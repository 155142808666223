import React from 'react';

function WACSFooter() {
  return (
    <div className="">
      <div className="flex flex-col md:flex-row py-10 md:px-16 md:gap-16 gap-6 justify-start  bg-[#4F2915] ">
        <div className="md:w-[15%] h-[100px] md:h-[80px]">
          <img
            src="/assets/wacs-logo.png"
            className="w-1/2 md:w-full h-full object-cover md:object-cover"
            alt='Logo'
          />
        </div>
        <div className="md:ml-16 ml-6">
          <span className="text-[#FF5500] mb-4 block font-bold">Menu</span>
          <ul className="text-white flex flex-col gap-2">
            <li>
              <a href="#">Home</a>
            </li>
            <li>
              <a href="#">About</a>
            </li>
            <li>
              <a href="#">Objectives</a>
            </li>
            <li>
              <a href="#">Partnerships</a>
            </li>
          </ul>
        </div>
        <div className="flex flex-col ml-6 md:ml-0 mb-4">
          <span className="text-[#FF5500] mb-4 ">Address</span>
          <span className="text-white">
            Boulevard de la présidence, Porto novo Bénin
          </span>
          <a
            className="my-3"
            href="https://www.facebook.com/61570540571082/posts/pfbid02i9QmZA6JQhpyn3DkWHzDjBJmN2SLxnNWjpgPuMADeYsLNAFigjFx8dfFuZnpj8nwl/?app=fbl"
          >
            <img className="w-[30px]" src="/assets/facebook.png" />
          </a>
        </div>
      </div>
      <div className="bg-black md:px-6 flex gap-3 justify-between items-center">
        <div className='w-1/2 md:w-auto'>
          <img src="/assets/footer.svg" alt="footer logo" />
        </div>
        <span className="block w-1/2 md:w-auto capitalize text-white">
          Copyright 2025. all right reserved. aiesec
        </span>
      </div>
    </div>
  );
}

export default WACSFooter;
