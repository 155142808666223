import React from 'react';
// import { useEffect } from 'react';
// import classes from './Carousel.module.css';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import Slider from 'react-slick';

function WACSCarousel() {
  const images = [
    'https://res.cloudinary.com/skycoder/image/upload/v1737316573/54108460072_26eb3f5abc_o_2_hcbpj9.png',
    'https://res.cloudinary.com/skycoder/image/upload/v1737316572/image_1_3_tknnrx.png',
  ];
  const settings = {
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 5000,
    arrows: false,
    dots:false,
  };

  return (
    <div className='' >
      <Slider {...settings}>
        {images.map((image, i) => {
          return (
            <div className="">
              <div key={i} className='w-full h-full' >
                <img
                  key={i}
                  alt={`AIESEC In Nigeria ${i}`}
                  src={image}
                  loading="lazy"
                  className='w-full md:h-full max-h-[80vh] h-[80vh] object-cover'
                />
              </div>
            </div>
          );
        })}
      </Slider>
    </div>
  );
}

export default WACSCarousel;
