import React from 'react'
// import WACSCarousel from './Carousel'

function RegisterHero() {
  return (
   
        <div className="relative h-[30vh]">
          <div className=" z-[10] absolute bg-gradient-to-r from-black to-transparent w-full h-[30vh] flex justify-start ">
            <div className="flex justify-center pl-5 md:pl-[9rem] flex-col">
              <h2 className="md:left-[6rem] capitalize text-white max-w-[400px] text-[30px] md:text-[36px] font-bold">
              Empowering Leaders, Driving Progress
              </h2>
    
            </div>
          </div>
          <div className='h-[30vh]'>
            <img className='w-full h-full object-cover' src="https://res.cloudinary.com/skycoder/image/upload/v1737316573/54108460072_26eb3f5abc_o_2_hcbpj9.png" alt="banner"/>
            </div>
        </div>

  )
}

export default RegisterHero