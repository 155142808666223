import React from 'react';
import WACSCarousel from './Carousel';
import { Link } from 'react-router-dom';

function WACSHero() {
  return (
    <div id='home' className="relative h-[80vh]">
      <div className=" z-[10] absolute bg-gradient-to-r from-black to-transparent w-full h-[80vh] flex justify-end  md:justify-start ">
        <div className="flex justify-center md:pl-[12rem] pl-8 flex-col">
          <h1 className="md:left-[6rem] capitalize text-white max-w-[500px] text-[32px] md:text-[40px] font-bold">
            Be part of this generation of young leaders{' '}
          </h1>

          <div className='text-white my-8 gap-1 flex flex-col'>
            <span className="uppercase">when and where?</span>
            <span className="capitalize text-[24px] md:text-[36px]">porto novo, Benin</span>
            <span className="capitalize text-[24px] md:text-[36px]">28 Feb - 04 Mar 2025</span>
          </div>

          <div>
            <Link to={'/wacs/register'} className="bg-[#FF5500] text-white p-4">
              Register Now
            </Link>
          </div>
        </div>
      </div>
      <WACSCarousel />
    </div>
  );
}

export default WACSHero;
