import React from 'react';

function WACSAbout() {
  return (
    <div id='about' className="max-w-[1300px] mx-auto flex flex-col md:flex-row justify-between items-center md:py-10 md:gap-12">
      <div className='bg-[#ff5500] h-[48px] w-full lg:hidden'></div>
      <div className="md:w-[50%]">
        <img
          src="https://res.cloudinary.com/skycoder/image/upload/v1737205640/Mask_group_3_h0x6pe.png"
          className="w-full"
          alt="Mercy Jacob"
        />
      </div>
      <div className="md:w-[50%] my-8 px-4 flex flex-col justify-between">
        <span className="uppercase tracking-wide text-sm my-4">About Seminar</span>
        <h2 className="font-bold text-pretty text-[#4F2915] mb-4 text-[32px] md:text-[46px]">
          We aim to reignite collaboration in the sub-region,
        </h2>
        <p className="leading-8 mb-4">
          Promoting sustainability and growth for our members and alumni.
          This edition focuses on the empowerment of young leaders and the
          meaningful to drive collective impact.
        </p>
        <button className="bg-[#FF5500] w-[125px] text-white p-2">
          Learn More
        </button>
      </div>
    </div>
  );
}

export default WACSAbout;
