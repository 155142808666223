import { Form, Input, Radio } from 'antd';
import React from 'react';

function RegisterInfo() {
  return (
    <div>
      <Form.Item  rules={[
            {
              required: true,
              message: "Is this your first conference?",
            }]}
             name="first_conference" label={<p>First Conference?</p>}>
        <Radio.Group
          name="first_conference"

          className='flex md:gap-10'
          options={[
            {
              value: true,
              label: (
                <span>
                  <strong>Yes</strong>, it’s my first time
                </span>
              ),
            },
            {
              value: false,
              label: (
                <span>
                  <strong>No</strong>, i have already participate it{' '}
                </span>
              ),
            }
          ]}
        ></Radio.Group>
      </Form.Item>
      <div className='flex md:flex-row flex-col gap-4 justify-between'>
        <Form.Item  rules={[
            {
              required: true,
              message: "Enter your expectations",
            }]} className='md:w-1/2' name="expectations" label={(<p>Expectation for this conference</p>)}>
          <Input.TextArea className="h-[56px] focus:border-[#4F2915] focus:shadow-none" autoSize={{
          minRows: 3,
          maxRows: 5,
        }} placeholder='Add Text'/>
        </Form.Item>
        <Form.Item  rules={[
            {
              required: true,
              message: "Enter your goals",
            }]} className='md:w-1/2' name="goals" label={(<p>Goal for this conference</p>)}>
        <Input.TextArea className="h-[56px] focus:border-[#4F2915] focus:shadow-none" autoSize={{
          minRows: 3,
          maxRows: 5,
        }} placeholder='Add Text'/>
        </Form.Item>

      </div>
      <Form.Item name="t_shirt_size"  rules={[
            {
              required: true,
              message: "Enter T-Shirt Size",
            }]} label={<p>T-Shirt Size?</p>}>
        <Radio.Group
        className='flex md:gap-10'
          name="t_shirt_size"
          options={[
            {
              value: 'S',
              label: 'S',
            },
            {
              value: 'M',
              label: 'M',
            },
            {
                value: 'L',
                label: 'L',
              },
              {
                value: 'XL',
                label: 'XL',
              },
              {
                value: 'XXL',
                label: 'XXL',
              },
              {
                value: 'XXXL',
                label: 'XXXL',
              }
          ]}
        ></Radio.Group>
      </Form.Item>
      <Form.Item name="socials"  rules={[
            {
              required: true,
              message: "Enter your social media handle",
            }]} label={(<p>Social Media Handle</p>)}>
      <Input className="h-[56px] focus:border-[#4F2915] focus:shadow-none" placeholder='Add Account Link'/>

      </Form.Item>
      <Form.Item name="can_stay_with_opposite_sex" rules={[
            {
              required: true,
              message: "Can you stay with the opposite sex?",
            }]} label={<p>Can you share a room with the opposite gender?</p>}>
        <Radio.Group
          name="can_stay_with_opposite_sex"
                  className='flex md:gap-10'
          options={[
            {
              value: "yes",
              label: (
                <span>
                  <strong>Yes</strong>, I can
                </span>
              ),
            },
            {
              value: "no",
              label: (
                <span>
                  <strong>No</strong>, I can't
                </span>
              ),
            }
          ]}
        ></Radio.Group>
      </Form.Item>
    </div>
  );
}

export default RegisterInfo;
