import { useEffect } from 'react';
import classes from './AboutUsLocalCommittees.module.css';
import Aos from 'aos';
import 'aos/dist/aos.css';
// import { useNavigate } from "react-router-dom";
// import { scrollToTheTop } from "../../HelperFunctions/scrollToTop";

const AboutUsLocalCommittees = ({ isComponent }) => {
  // Utils
  const ourLCs = [
    {
      name: 'Abeokuta',
    },
    {
      name: 'Abuja',
    },
    {
      name: 'Akure',
    },
    {
      name: 'Benin',
    },
    {
      name: 'Benue',
    },
    {
      name: 'Calabar',
    },
    {
      name: 'Enugu',
    },
    {
      name: 'Ibadan',
    },
    {
      name: 'Ife',
    },
    {
      name: 'Ilorin',
    },
    {
      name: 'Jos',
    },
    {
      name: 'Kano',
    },
    {
      name: 'Lagos',
    },
    {
      name: 'Portharcourt',
    },
  ];

  // Navigate
  // const navigate = useNavigate();

  // Effects
  useEffect(() => {
    Aos.init({ duration: 1000 });
  }, []);

  return (
    <section
      className={`${classes.container} ${
        isComponent ? classes.isComponentContainer : undefined
      }`}
    >
      <div className={classes.header} data-aos="fade-right">
        <h4>
          <span className={classes.aboutText}>OUR </span> LCS
        </h4>
        <p>AIESEC is present is all these locations within the country</p>
      </div>
      <div className={classes.ourLCSection}>
        {ourLCs.map((data, i) => {
          return (
            <div
              key={i}
              className={classes.LC}
              data-aos="fade-down"
              // onClick={() => {
              //   navigate(
              //     `/local-committee/${data.name.toLowerCase().replace("-", "")}`
              //   );
              //   scrollToTheTop();
              // }}
            >
              <span>{data.name}</span>
              {/* <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
              >
                <path
                  d="M17.001 20H6.00098C4.89641 20 4.00098 19.1046 4.00098 18V7C4.00098 5.89543 4.89641 5 6.00098 5H10.001V7H6.00098V18H17.001V14H19.001V18C19.001 19.1046 18.1055 20 17.001 20ZM11.701 13.707L10.291 12.293L16.584 6H13.001V4H20.001V11H18.001V7.415L11.701 13.707Z"
                  fill={isComponent ? "#fff" : "#2E3A59"}
                />
              </svg> */}
            </div>
          );
        })}
      </div>
    </section>
  );
};

export default AboutUsLocalCommittees;
