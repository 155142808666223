import React from 'react';
import { Link } from 'react-router-dom';

function WACSCTA() {
  return (
    <div id='partnership'>
      <div className="mx-auto flex text-center gap-4 justify-center flex-col max-w-[1300px]">
        
        <div className="md:h-[90vh] my-16 md:my-auto flex justify-center flex-col relative">
          <div className="absolute hidden lg:block top-0 left-[12rem]">
            <img
              className="w-full"
              src="https://res.cloudinary.com/skycoder/image/upload/v1737315263/Mask_group_5_vi8ajj.png"
              alt="AIESECer"
            />
          </div>
          <div className="absolute hidden lg:block max-w-[250px] right-[10rem] bottom-[20px]">
            <img
              className="w-full"
              src="https://res.cloudinary.com/skycoder/image/upload/v1737315263/Mask_group_4_o8wi9a.png"
              alt="AIESECer"
            />
          </div>
          <div className="absolute hidden lg:block max-w-[250px] top-0 right-[5rem]">
            <img
              className="w-full"
              src="https://res.cloudinary.com/skycoder/image/upload/v1737315263/Mask_group_6_j6su2o.png"
              alt="AIESECer"
            />
          </div>
          <div className="absolute hidden lg:block bottom-[3rem] left-[15rem]">
            <img
              className="w-full"
              src="https://res.cloudinary.com/skycoder/image/upload/v1737315263/Mask_group_7_jvshsx.png"
              alt="AIESECer"
            />
          </div>
          <div className="absolute hidden lg:block top-[30%]">
            <img
              className="w-full"
              src="https://res.cloudinary.com/skycoder/image/upload/v1737316214/Mask_group_9_ezvyop.png"
              alt="AIESECer"
            />
          </div>
          <span className="uppercase text-sm md:text-[18px] ">partnership</span>
          <h2 className="text-[#4F2915] max-w-[700px] mx-auto text-pretty  text-center font-bold text-[28px] md:text-[40px] capitalize">
            west african connecting seminar Objectives
          </h2>

          <button className="bg-[#FF5500] max-w-[250px] mx-auto text-white p-4 mt-8">
            Consult Partner Form
          </button>
        </div>
      </div>
      <div className="bg-[#FF5500]">
        <div className="md:max-w-[600px] md:flex-row flex-col flex md:gap-10 gap-5 items-center py-10 mx-auto">
          <span className="font-bold text-white">
            Empowering Leaders, Driving Progress
          </span>
          <button className="bg-[#EDEAE8] text-[#ff5500] py-3 px-6 ">
            <Link to={'/wacs/register'}>Register Now</Link>
          </button>
        </div>
      </div>
    </div>
  );
}

export default WACSCTA;
