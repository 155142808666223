import React from 'react';

function WACSObjectives() {
  return (
    <div id='objectives' className="max-w-[1300px] mt-16 mb-10 px-4 mx-auto">
      <span className="uppercase text-sm md:text-[16px]">Objectives</span>
      <h2 className="capitalize font-bold text-[32px] md:text-[46px] text-[#4F2915]">
        west african connecting seminar Objectives
      </h2>
      <div className="flex flex-col justify-between gap-4">
        <div className="bg-[#00964E] mt-8 md:items-center p-6 justify-between md:p-10 text-white gap-6 md:gap-0 flex flex-col md:flex-row">
          <span className="font-bold text-[#EDEAE8] md:w-[4%] text-[46px] md:text-[56px]">1</span>
          <span className="text-[26px] md:w-[25%]">
            Facilitate collaborative growth and exchange.
          </span>
          <p className="md:w-[68%] leading-[1.5em] text-pretty md:text-[18px]">
            Explore how collaboration drives innovation and collective progress.
            At WACS, we create a space where ideas, expertise, and resources
            intersect to foster mutual growth. Through dynamic workshops,
            engaging discussions, and meaningful networking opportunities, we
            empower participants to share knowledge and build lasting
            partnerships. Together, we shape a future defined by shared success.
          </p>
        </div>
        <div className="bg-[#999200] md:items-center p-6 justify-between md:p-10 text-white gap-6 md:gap-0 flex flex-col md:flex-row">
          <span className="font-bold text-[#EDEAE8] md:w-[4%] text-[46px] md:text-[56px]">2</span>
          <span className="text-[26px] md:w-[25%]">
            Strengthen connections among MCPs, Alumni, and Members.
          </span>
          <p className="md:w-[65%] text-pretty text-[18px]">
            Foster meaningful relationships between current MCPs, alumni, and
            active members of the community. At WACS, we provide a platform to
            celebrate shared experiences, exchange valuable insights, and build
            bridges across generations. By connecting past and present leaders,
            we ensure the continuity of knowledge, collaboration, and a shared
            vision for the future.
          </p>
        </div>
        <div className="bg-[#993300] md:items-center p-6 justify-between md:p-10 text-white gap-6 md:gap-0 flex flex-col md:flex-row">
          <span className="font-bold text-[#EDEAE8] md:w-[5%]  text-[46px] md:text-[56px]">3</span>
          <span className="text-[26px] md:w-[25%]">
            Build leadership skills aligned with strategic goals.
          </span>
          <p className="md:w-[65%] text-pretty text-[18px]">
            Empower individuals to develop leadership capabilities that drive
            organizational success. At WACS, we focus on equipping participants
            with the tools and insights needed to align personal growth with
            strategic objectives. Through expert-led sessions and practical
            exercises, attendees will learn to lead with purpose, inspire teams,
            and deliver impactful results.
          </p>
        </div>
        <div className="bg-[#FF5500] md:items-center p-6 justify-between md:p-10 text-white gap-6 md:gap-0 flex flex-col md:flex-row">
          <span className="font-bold text-[#EDEAE8] md:w-[5%]  text-[46px] md:text-[56px]">4</span>
          <span className="text-[26px] md:w-[25%]">
            Provide a platform for exchange approvals and innovation.
          </span>
          <p className="md:w-[65%] text-pretty text-[18px]">
            Foster meaningful relationships between current MCPs, alumni, and
            active members of the community. At WACS, we provide a platform to
            celebrate shared experiences, exchange valuable insights, and build
            bridges across generations. By connecting past and present leaders,
            we ensure the continuity of knowledge, collaboration, and a shared
            vision for the future.
          </p>
        </div>
      </div>
    </div>
  );
}

export default WACSObjectives;
