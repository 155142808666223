import React from 'react'
import WACSNavbar from '../../Components/WACS/Navbar/Navbar'
import WACSHero from '../../Components/WACS/Navbar/Hero'
import WACSAbout from '../../Components/WACS/Navbar/About'
import WACSObjectives from '../../Components/WACS/Navbar/Objectives'
import WACSCTA from '../../Components/WACS/Navbar/CTA'
import WACSFooter from '../../Components/WACS/Navbar/Footer'


function WACS() {
  return (
    <div>
        <WACSNavbar/>
        <WACSHero/>
        <WACSAbout/>
        <WACSObjectives/>
        <WACSCTA/>
        <WACSFooter/>
    </div>
  )
}

export default WACS