const OurLocalCommittees = () => {
  const LCS = [
    'Abeokuta',
    'Abuja',
    'Akure',
    'Benin',
    'Benue',
    'Calabar',
    'Enugu',
    'Ibadan',
    'Ife',
    'Ilorin',
    'Jos',
    'Kano',
    'Lagos',
    'Portharcourt',
  ];

  return (
    <div className="flex flex-col gap-6 mb-16">
      <div className="flex flex-col gap-4 bg-[#037ef3] py-8 text-white px-3 md:px-0">
        <h2 className="text-3xl md:text-5xl font-extralight italic text-center text-white">
          OUR <span className="font-bold not-italic">LOCAL COMMUNITIES</span>
        </h2>
        <p className="font-light italic text-xl leading-8 text-center">
          AIESEC is present in all these locations within the country.
        </p>
      </div>
      <div className="grid grid-cols-2 md:grid-cols-4 gap-6 w-full justify-items-center px-[10%]">
        {LCS.map((LC, index) => (
          <button
            key={index}
            className={`bg-[#20252F] text-white w-[160px] text-xl sm:text-lg sm:w-[240px] lg:text-2xl px-4 py-3 sm:px-3 sm:py-2 md:py-4 md:w-[140px] lg:w-[190px] xl:w-[240px] rounded-lg cursor-pointer
              active:scale-95 active:bg-opacity-90 ${
                index >= LCS.length - 2 ? 'md:col-span-2 ' : ''
              }`}
          >
            {LC}
          </button>
        ))}
      </div>
    </div>
  );
};

export default OurLocalCommittees;
