import React, { useState } from 'react';
import { Link } from 'react-router-dom';

function WACSNavbar() {
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

  const toggleMobileMenu = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
  };

  return (
    <div>
      {/* Language Selector */}
      <div className="bg-[#4F2915]">
        <div className="max-w-[1300px] mx-auto py-4 px-4 text-white flex justify-between">
          <div className="text-sm md:text-[16px]">
            <button className="border-r uppercase pr-2 border-white bg-transparent">
              English
            </button>
            <button className="pl-2 opacity-50 uppercase bg-transparent">
              French
            </button>
          </div>
        </div>
      </div>

      {/* Main Navbar */}
      <div className="bg-[#FF5500]">
        <div className="max-w-[1300px] relative flex px-4 items-center justify-between mx-auto py-2 text-white">
          {/* Logo */}
          <div className="md:w-[15%] w-[130px] h-[80px]">
            <img
              src="/assets/wacs-logo.png"
              className="w-full h-full object-cover"
              alt="WACS Logo"
            />
          </div>
          {/* Mobile Menu Button */}
          <div className="lg:hidden ">
            <button
              onClick={toggleMobileMenu}
              className="text-white focus:outline-none"
            >
              {isMobileMenuOpen ? (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-6 w-6"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M6 18L18 6M6 6l12 12"
                  />
                </svg>
              ) : (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-6 w-6"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M4 6h16M4 12h16m-7 6h7"
                  />
                </svg>
              )}
            </button>
          </div>
          {/* Desktop Menu */}
          <div className="hidden lg:flex justify-between items-center gap-4">
            <a href="#home" className="text-white hover:underline">
              Home
            </a>
            <a href="#about" className="text-white hover:underline">
              About
            </a>
            <a href="#objectives" className="text-white hover:underline">
              Objectives
            </a>
            <a href="#partnership" className="text-white hover:underline">
              Partnership
            </a>
          </div>

          {/* Register Button */}
          <Link to={'/wacs/register'} className="hidden lg:block bg-[#EDEAE8] text-[#FF5500] px-4 py-2 rounded-sm">
            Register Now
          </Link>
        </div>
      </div>

      {/* Mobile Menu */}
      {isMobileMenuOpen && (
        <div className="bg-[#FF5500] absolute z-[12] w-full h-[200px] lg:hidden">
          <div className="flex flex-col items-start gap-2 p-4 text-white">
            <a href="#home" className="block w-full text-white hover:underline">
              Home
            </a>
            <a href="#about" className="block w-full text-white hover:underline">
              About
            </a>
            <a href="#objectives" className="block w-full text-white hover:underline">
              Objectives
            </a>
            <a href="#partnership" className="block w-full text-white hover:underline">
              Partnership
            </a>
            <button className="bg-[#EDEAE8] text-[#FF5500] px-4 py-2 rounded-sm w-full mt-2">
              Register Now
            </button>
          </div>
        </div>
      )}
    </div>
  );
}

export default WACSNavbar;
