import React from 'react';
// import AIESECMember from '../../Assets/Images/AIESECMember.svg';
import classes from './FormContainer.module.css';
import Button from '../../Components/Button/Button';
import axios from 'axios';
import toast from 'react-hot-toast';
import { Alert } from '@mui/material';
import { capitalize } from '../../HelperFunctions/capitalize';
import { useEffect, useState } from 'react';

const FormContainer = () => {
  const [submitForm, setSubmitForm] = useState({
    isLoading: false,
    data: null,
    error: null,
  });

  const formRef = React.useRef();

  const cities = [
    // 'Abeokuta',
    'Abuja',
    'Akure',
    'Benin',
    'Benue',
    'Calabar',
    'Enugu',
    'Ibadan',
    'Ife',
    // 'Ilorin',
    'Jos',
    'Kano',
    // 'Lagos',
    'Port-Harcourt',
    'Others',
  ];
  const universities = {
    Abeokuta: [
      'Federal university of Agriculture Abeokuta',
      'Olabisi Onabanjo University',
      'Moshood Abiola Polytechnic Abeokuta',
      'Federal College of Education Osiele',
    ],
    Akure: ['Federal University of Technology, Akure'],
    Abuja: ['University of Abuja', 'Bingham university', 'Nile University'],
    Benin: ['University of Benin', 'Benson Idahosa University'],
    Benue: ['Benue State University', 'Joseph Sarwuan Tarka University'],
    Calabar: [
      'University of Calabar',
      'University of Cross River',
      'Arthur Jarvis University',
      'College of Health Technology',
      'School of Nursing',
    ],
    Enugu: [
      'University of Nigeria, Nsukka',
      'University of Nigeria, Enugu Campus (UNEC)',
      'Enugu State University of Science and Technology',
      'Federal College of Dental Technology and Therapy Enugu',
    ],
    Ife: ['Obafemi Awolowo University', 'Oduduwa University'],
    Ibadan: [
      'University of Ibadan',
      'Lead City University',
      'McPherson University',
      'Dominican university',
    ],
    Ilorin: [
      'University of Ilorin',
      'Kwara State University',
      'Kwara State Polytechnic',
      'Al-Hikmah University',
      'Landmark University',
    ],
    Jos: [
      'University of Jos',
      'National Film Institute Jos',
      'ATBU Bauchi',
      'Plateau State University',
    ],
    Kano: ['Skyline University'],
    Lagos: [
      'University of Lagos',
      'Lagos State University',
      'Yaba College of Technology',
      'Lagos State University of Science and Technology, Ikorodu',
      'Lagos State Polytechnic, Ikorodu',
    ],
    'Port-Harcourt': [
      'University of Port Harcourt',
      'Ignatius Ajuru University of Education',
      'Rivers State University',
    ],
    Others: [],
  };

  const referralSources = [
    'Instagram',
    'Facebook',
    'LinkedIn',
    'Twitter',
    'Email',
    'WhatsApp',
    'AIESEC Event',
    'Word of Mouth',
    'Friend',
    'Other',
  ];

  const [formData, setFormData] = React.useState({
    first_name: '',
    last_name: '',
    phone: '',
    email: '',
    dob: '',
    academic_situation: '',
    residence: '',
    school_location: '',
    other_names: '',
    name_of_school: '',
    course: '',
    level: '',
    aiesec_participation: '',
    event_participated: '',
    motivation: '',
    interest: '',
    skills: '',
    hear_about_recruitment: '',
  });

  useEffect(() => {
    if (formData.school_location) {
      setFormData((prevState) => {
        return {
          ...prevState,
          name_of_school: universities[formData?.school_location][0],
        };
      });
    }

    if (formData.academic_situation === 'graduate') {
      setFormData((prevState) => {
        return { ...prevState, name_of_school: '' };
      });
    }

    // eslint-disable-next-line
  }, [formData?.school_location, formData.academic_situation]);

  const [formErrors] = React.useState({
    first_nameError: '',
    last_nameError: '',
    phoneError: '',
    emailError: '',
    dobError: '',
    academic_situationError: '',
    residenceError: '',
    school_locationError: '',
    name_of_schoolError: '',
    courseError: '',
    levelError: '',
    aiesec_participationError: '',
    event_participatedError: '',
    motivationError: '',
    interestError: '',
    skillsError: '',
    hear_about_recruitmentError: '',
  });

  const handleChange = (e) => {
    e.preventDefault();
    setFormData((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };

  const handleSubmit = () => {
    setSubmitForm({ isLoading: true, data: null, error: null });
    if (!formRef.current.checkValidity()) {
      toast.error('You have empty field(s)');
      return;
    }
    axios
      .post('https://ain-backend.fly.dev/api/recruitment', formData)
      .then((res) => {
        toast.success(res.data.detail);
        setSubmitForm({ isLoading: false, data: res.data.detail, error: null });
        setFormData({
          first_name: '',
          last_name: '',
          phone: '',
          email: '',
          dob: '',
          academic_situation: '',
          residence: '',
          school_location: '',
          other_names: '',
          name_of_school: '',
          course: '',
          level: '',
          aiesec_participation: '',
          event_participated: '',
          motivation: '',
          interest: '',
          skills: '',
          hear_about_recruitment: '',
        });
      })
      .catch((err) => {
        toast.error(err.response.data.detail);
        setSubmitForm({
          isLoading: false,
          data: null,
          error: err.response.data.detail,
        });
      });
  };
  const [showForm, setShowForm] = useState(false);

  return (
    <div className={`${classes.innerContainer} py-10`}>
      {/* <div className={classes.roles}>
        <div className={classes.topImage}>
          <img src={AIESECMember} alt="AIESEC member logo" loading="lazy" />
        </div>
        <div className={classes.rolesText}>
          <h4>AIESEC IN NIGERIA IS NO LONGER RECRUITING.</h4>
          <p>
            Join one of our local teams and develop yourself through practical
            experiences in the world’s largest youth-run and led organisation
          </p>
          <p>
            While this might be a bummer, there's so much more to explore on our
            homepage. You'll be amazed by the endless opportunities to lead and
            create an impact with AIESEC.
          </p>
          <p>
            That's not all, we've got so much to offer! Stay connected with us
            on our social media platforms and never miss out on exciting
            updates, events, and engagement opportunities with AIESEC.
          </p>
          <p>We can't wait to connect with you!</p>
        </div>
      </div> */}
      <div className="flex flex-col gap-3 text-center text-white w-full sm:w-[90%] md:w-[80%] lg:w-[70%] mx-auto items-center px-3 md:px-0 mb-10 ">
        <h2 className="font-bold leading-[56px] text-5xl text-white">
          Ready to join the community?
        </h2>
        <p className="leading-8 text-xl font-light italic w-full xl:w-[75%]">
          Join one of our local teams and develop yourself through practical
          experiences in the world’s largest youth lead organisation
        </p>
        {!showForm && (
          <button
            className="bg-white text-[#037EF3] font-bold py-4 px-6 rounded-lg hover:bg-gray-100 transition duration-300 w-[90%] md:w-[30%] lg:w-[20%]"
            onClick={() => setShowForm(true)}
          >
            Get Started
          </button>
        )}
      </div>

      {showForm && (
        <div
          className={`${
            showForm && !submitForm.data ? classes.boxForm : ''
          } mx-auto sm:max-w-[500px] md:max-w-[550px] lg:max-w-[800px]`}
        >
          {submitForm.data ? (
            <div
              className="fixed top-0 left-0 w-full h-full bg-black bg-opacity-80 flex justify-center items-center z-50"
              onClick={() =>
                setSubmitForm((prevState) => ({ ...prevState, data: null }))
              }
            >
              <div
                className={`bg-white items-center justify-center p-8 rounded-lg shadow-lg w-[95%] sm:w-[70%] lg:w-[80%] h-[240px] sm:h-[200px] md:h-[300px] lg:h-[240px] xl:h-[200px] text-center`}
                onClick={(e) => e.stopPropagation()}
              >
                <div className="flex flex-col gap-4">
                  <h3 className="text-2xl md:text-[36px] lg:text-[40px] font-bold text-[#037EF3] leading-8 md:leading-10">
                    Thank you for your interest in joining AIESEC!
                  </h3>

                  <p className="italic text-sm md:text-xl leading-6 md:leading-8 text-[#52565E]">
                    We're excited to have you begin your leadership journey.
                    Please check your email for a confirmation message with
                    further information. We look forward to welcoming you to
                    AIESEC!
                  </p>
                </div>
                {/* <Alert severity="success">{capitalize(submitForm?.data)}</Alert> */}
                {/* <Button
                onClick={() => {
                  setSubmitForm((prevState) => {
                    return { ...prevState, data: null };
                  });
                }}
              >
                Submit another application
              </Button>{' '} */}
              </div>
            </div>
          ) : (
            <form
              id="memberDetails"
              ref={formRef}
              onSubmit={(e) => {
                e.preventDefault();
              }}
            >
              <div className={`${classes.error}`}>
                {submitForm?.error && (
                  <Alert severity="error">
                    {capitalize(submitForm?.error)}
                  </Alert>
                )}
              </div>
              <div className="flex flex-col gap-5">
                <h3 className="font-bold text-[32px] text-[#52565E]">
                  Personal Information
                </h3>
                <div className={`${classes.One} flex flex-col md:flex-row`}>
                  <div
                    className={`${`${classes.oneChild}`}`}
                    error={!!formErrors.first_nameError}
                  >
                    <label for="fname" className="font-bold">
                      First name
                    </label>
                    <input
                      type="text"
                      id="first_name"
                      name="first_name"
                      placeholder="John"
                      value={formData.first_name}
                      onChange={handleChange}
                      required
                    />
                  </div>
                  <div
                    className={`${`${classes.oneChild}`}`}
                    error={!!formErrors.last_nameError}
                  >
                    <label for="lname" className="font-bold">
                      Last name
                    </label>
                    <input
                      type="text"
                      id="last_name"
                      name="last_name"
                      placeholder="Doe"
                      value={formData.last_name}
                      onChange={handleChange}
                      required
                    />
                  </div>
                </div>
                <div className={`${classes.One} flex flex-col md:flex-row`}>
                  <div
                    className={`${`${classes.oneChild}`}`}
                    error={!!formErrors.dobError}
                  >
                    <label for="dbirth" className="font-bold">
                      Date of Birth
                    </label>
                    <input
                      type="date"
                      id="dob"
                      name="dob"
                      placeholder="dd/mm/yyyy"
                      value={formData.dob}
                      onChange={handleChange}
                      required
                    />
                  </div>
                  <div
                    className={`${classes.oneChild}`}
                    error={!!formErrors.phoneError}
                  >
                    <label for="phone" className="font-bold">
                      Phone Number
                    </label>
                    <input
                      type="text"
                      id="phone"
                      name="phone"
                      placeholder="+234 000 000 0000"
                      value={formData.phone}
                      onChange={handleChange}
                      required
                    />
                  </div>
                </div>
                <div
                  className={`${classes.oneChild}`}
                  error={!!formErrors.emailError}
                >
                  <label for="email" className="font-bold">
                    Email Address
                  </label>
                  <input
                    type="email"
                    id="email"
                    name="email"
                    placeholder="johndoe@gmail.com"
                    value={formData.email}
                    onChange={handleChange}
                    required
                  />
                </div>
              </div>
              <div className="flex flex-col gap-5">
                <h3 className="font-bold text-3xl md:text-[32px] text-[#52565E]">
                  Academic Information
                </h3>
                <div
                  className={`${classes.oneChild}`}
                  error={!!formErrors.academic_situationError}
                >
                  <label for="acad" className="font-bold">
                    Academic Situation
                  </label>
                  <select
                    id="academic_situation"
                    name="academic_situation"
                    value={formData.academic_situation}
                    onChange={handleChange}
                  >
                    <option value="" disabled>
                      Select an option
                    </option>
                    <option value="student">Student</option>
                    <option value="graduate">Graduate</option>
                  </select>
                </div>
                <div
                  className={`${classes.oneChild}`}
                  error={!!formErrors.residenceError}
                  style={{
                    display:
                      formData.academic_situation === 'graduate'
                        ? 'block'
                        : 'none',
                  }}
                >
                  <label for="residence" className="font-bold">
                    Not a Student? Where do you reside?
                  </label>
                  <input
                    type="text"
                    id="residence"
                    name="residence"
                    placeholder="Lafia"
                    value={formData.residence}
                    onChange={handleChange}
                  />
                </div>
                <div
                  className={`${classes.oneChild}`}
                  error={!!formErrors.school_locationError}
                  style={{
                    display:
                      formData.academic_situation === 'graduate'
                        ? 'none'
                        : 'block',
                  }}
                >
                  <label for="location" className="font-bold">
                    Location of academic institution
                  </label>
                  <select
                    id="school_location"
                    name="school_location"
                    value={formData.school_location}
                    onChange={handleChange}
                  >
                    <option value="" disabled>
                      Select an option
                    </option>
                    {cities.map((city) => (
                      <option value={city}>{city}</option>
                    ))}
                  </select>
                </div>
                <div
                  className={`${classes.oneChild}`}
                  error={!!formErrors.school_locationError}
                  style={{
                    display:
                      formData.academic_situation === 'graduate'
                        ? 'none'
                        : formData.school_location === 'Others'
                        ? 'none'
                        : 'block',
                  }}
                >
                  <label for="location" className="font-bold">
                    Name of academic institution
                  </label>
                  <select
                    id="name_of_school"
                    name="name_of_school"
                    value={formData.name_of_school}
                    onChange={handleChange}
                  >
                    <option value="" disabled>
                      Select an option
                    </option>
                    {formData?.school_location &&
                      universities[formData.school_location].map((city) => (
                        <option value={city}>{city}</option>
                      ))}
                  </select>
                </div>
                <div
                  className={`${classes.oneChild}`}
                  error={!!formErrors.name_of_schoolError}
                  style={{
                    display:
                      formData.academic_situation === 'graduate'
                        ? 'none'
                        : formData.school_location === 'Others'
                        ? 'block'
                        : 'none',
                  }}
                >
                  <label for="institution" className="font-bold">
                    Can't find your State or University here? Please tell us.
                  </label>
                  <input
                    type="text"
                    id="other_names"
                    name="other_names"
                    placeholder="Covenant University, Ogun state"
                    value={formData.other_names}
                    onChange={handleChange}
                  />
                </div>
                <div
                  className={`${classes.oneChild}`}
                  error={!!formErrors.courseError}
                  style={{
                    display:
                      formData.academic_situation === 'graduate'
                        ? 'none'
                        : 'block',
                  }}
                >
                  <label for="course" className="font-bold">
                    Course of Study
                  </label>
                  <input
                    type="text"
                    id="course"
                    name="course"
                    placeholder="Medicine"
                    value={formData.course}
                    onChange={handleChange}
                  />
                </div>
                <div
                  className={`${classes.oneChild}`}
                  error={!!formErrors.levelError}
                  style={{
                    display:
                      formData.academic_situation === 'graduate'
                        ? 'none'
                        : 'block',
                  }}
                >
                  <label for="level" className="font-bold">
                    Level of Study
                  </label>
                  <input
                    type="text"
                    id="level"
                    name="level"
                    placeholder="Final year"
                    value={formData.level}
                    onChange={handleChange}
                  />
                </div>
              </div>
              <div className="flex flex-col gap-5">
                <h3 className="font-bold text-[32px] text-[#52565E]">
                  Interests
                </h3>
                <div
                  className={`${classes.oneChild}`}
                  error={!!formErrors.eventError}
                >
                  <label for="event" className="font-bold">
                    Have you participated in any AIESEC event(s) before?
                  </label>
                  <select
                    id="aiesec_participation"
                    name="aiesec_participation"
                    value={formData.aiesec_participation}
                    onChange={handleChange}
                    required
                  >
                    <option value="" disabled selected>
                      Select an option
                    </option>
                    <option value="yes">Yes</option>
                    <option value="no">No</option>
                  </select>
                </div>
                <div
                  className={`${classes.oneChild}`}
                  error={!!formErrors.event_partcipatedError}
                >
                  <label for="aiesec" className="font-bold">
                    If yes, which AIESEC event(s)?
                  </label>
                  <input
                    type="text"
                    id="event_participated"
                    name="event_participated"
                    placeholder=""
                    value={formData.event_participated}
                    onChange={handleChange}
                  />
                </div>
                <div
                  className={`${classes.oneChild}`}
                  error={!!formErrors.motivationError}
                >
                  <label for="motivation" className="font-bold">
                    What is your motivation for joining AIESEC?
                  </label>
                  <input
                    type="text"
                    id="motivation"
                    name="motivation"
                    placeholder=""
                    value={formData.motivation}
                    onChange={handleChange}
                    required
                  />
                </div>
                <div
                  className={`${classes.oneChild}`}
                  error={!!formErrors.interestError}
                >
                  <label for="interest" className="font-bold">
                    Which of these are you interested in?
                  </label>
                  <select
                    id="interest"
                    name="interest"
                    value={formData.interest}
                    onChange={handleChange}
                    required
                  >
                    <option value="" disabled selected>
                      Select an option
                    </option>
                    <option value="marketing">Brand and Marketing</option>
                    <option value="sales">Sales Operations</option>
                    <option value="partnerships_and_business">
                      Partnerships and Business Development
                    </option>
                    <option value="program_management">
                      Program Management
                    </option>
                    <option value="human_resources">Human Resources</option>
                    <option value="finance">Finance</option>
                  </select>
                </div>
                <div
                  className={`${classes.oneChild}`}
                  error={!!formErrors.skillsError}
                >
                  <label for="skills" className="font-bold">
                    Do you have any specific skills you would like us to know
                    about?
                  </label>
                  <input
                    type="text"
                    id="skills"
                    name="skills"
                    placeholder="Photography"
                    value={formData.skills}
                    onChange={handleChange}
                    required
                  />
                </div>
                <div
                  className={`${classes.oneChild}`}
                  error={!!formErrors.hear_about_recruitmentError}
                >
                  <label for="channel" className="font-bold">
                    How did you hear about recruitment?
                  </label>
                  <select
                    id="hear_about_recruitment"
                    name="hear_about_recruitment"
                    value={formData.hear_about_recruitment}
                    onChange={handleChange}
                    required
                  >
                    <option value="" disabled selected>
                      Select an option
                    </option>
                    {referralSources.map((source) => (
                      <option key={source} value={source}>
                        {source}
                      </option>
                    ))}
                  </select>
                  {/* <input
                    type="text"
                    id="hear_about_recruitment"
                    name="hear_about_recruitment"
                    placeholder="Instagram"
                    value={formData.hear_about_recruitment}
                    onChange={handleChange}
                    required
                  /> */}
                </div>
              </div>
              <Button
                disabled={submitForm?.isLoading}
                onClick={() => handleSubmit()}
                loading={submitForm?.isLoading}
              >
                {' '}
                Send Application{' '}
              </Button>
            </form>
          )}
        </div>
      )}
    </div>
  );
};

export default FormContainer;
