import { Form, Input, Radio } from 'antd';
import React from 'react';

function AdditionalInfo() {
  return (
    <div>
      <Form.Item rules={[
            {
              required: true,
              message: "Enter any food requests",
            }]} name="food_request" label={<p>Any Specific Food Request?</p>}>
        <Input.TextArea
          autoSize={{
            minRows: 3,
            maxRows: 5,
          }}
          placeholder="Food Requests"
        />
      </Form.Item>
      <Form.Item rules={[
            {
              required: true,
              message: "Any allergies?",
            }]} name="have_allergies" label={<p>Do you have any allergies?</p>}>
        <Radio.Group
          name="have_allergies"
          className="flex md:gap-16"
          options={[
            {
              value: true,
              label: (
                <span>
                  <strong>Yes</strong>, I do
                </span>
              ),
            },
            {
              value: false,
              label: (
                <span>
                  <strong>No</strong>, I don't
                </span>
              ),
            },
          ]}
        ></Radio.Group>
      </Form.Item>

      <Form.Item name="emergencyContact" rules={[
            {
              required: true,
              message: "Enter your emergency contact",
            }]} label={<p>Emergency Contact</p>}>
        <Input className="h-[56px] focus:border-[#4F2915] focus:shadow-none" placeholder="Ex. +2258376392" />
      </Form.Item>
      <Form.Item
        name="availability"
        rules={[
          {
            required: true,
            message: "Enter your availability",
          }]}
        label={<p>Available from 28 February to 04 March</p>}
      >
        <Radio.Group
          name="availability"
          className="flex md:gap-16"
          options={[
            {
              value: true,
              label: (
                <span>
                  <strong>Yes</strong>
                </span>
              ),
            },
            {
              value: false,
              label: (
                <span>
                  <strong>No</strong>
                </span>
              ),
            },
          ]}
        ></Radio.Group>
      </Form.Item>
    </div>
  );
}

export default AdditionalInfo;
