import React, { useState } from 'react';
import WACSFooter from '../../Components/WACS/Navbar/Footer';
import WACSNavbar from '../../Components/WACS/Navbar/Navbar';
import RegisterHero from '../../Components/WACS/Navbar/RegisterHero';
// import { Button, Form, message, Steps, theme } from 'antd';
import { Button, Form, message, Steps } from 'antd';
import PersonalInformation from '../../Components/WACS/Register/PersonalInfo';
import RegisterInfo from '../../Components/WACS/Register/RegisterInfo';
import AdditionalInfo from '../../Components/WACS/Register/AdditionalInfo';
import './styles.css';
import { useMutation } from '@tanstack/react-query';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { Loading3QuartersOutlined } from '@ant-design/icons';
const steps = [
  {
    icon: (
      <span
        className={` border rounded-full flex gap-2 py-1 h-[40px] text-sm items-center font-bold  px-3`}
      >
        <span className="rounded-full border-white border h-[24px] w-[24px] font-normal flex justify-center items-center text-center">
          1
        </span>
        Personal Information
      </span>
    ),
    content: <PersonalInformation />,
    title: <></>,
  },
  {
    icon: (
      <span
        className={` border rounded-full flex gap-2 py-1 h-[40px] text-sm items-center font-bold text-white px-3`}
      >
        <span className="rounded-full border-white border h-[24px] w-[24px] font-normal flex justify-center items-center text-center">
          2
        </span>
        Registration Information
      </span>
    ),
    content: <RegisterInfo />,
    title: <></>,
  },
  {
    icon: (
      <span
        className={` border rounded-full flex gap-2 py-1 h-[40px] text-sm items-center font-bold text-white px-3`}
      >
        <span className="rounded-full border-white border h-[24px] w-[24px] font-normal flex justify-center items-center text-center">
          3
        </span>
        Additional Information
      </span>
    ),
    content: <AdditionalInfo />,
    title: <></>,
  },
];
function WACSRegister() {
  // const [step, setStep] = useState(0);
  const navigate = useNavigate()
  const [form] = Form.useForm();
  const [current, setCurrent] = useState(0);
  const handleHome = () => {
    navigate("/wacs");
    message.success("You have successfully registered!");
  };
  const submitForm = async (data) => {
    try{
      const response = await axios.post('https://ain-backend.fly.dev/api/wacs2025/register',data);
      
      // console.log("response", response);  
      // console.log("data", data);
      
      return response.data;
    } catch (error) {
      // console.log('error', error);
    }
   
  };
  const {mutate, isPending} = useMutation({
    mutationFn: submitForm,
    onError: (error) => {
      // console.log('error', error);
      if ( (error?.response?.data.message).includes('already exists')) {
        message.error('You have already registered. Check your email.');
        navigate('/wacs');
      }
    },
    onSuccess: (data) => {
      // console.log('data', data);
      message.success('Thank you for registering, Check your email')
      handleHome();
    },
  })
  
  const next = async () => {
    try {
      const values = await form.validateFields();

      setFormData((prev) => ({ ...prev, ...values }));
      setCurrent(current + 1);
    } catch (error) {
      console.log(error);
    }
  };
  const prev = () => {
    setCurrent(current - 1);
  };
  const onChange = (value) => {
    console.log('onChange:', value);
    setCurrent(value);
  };
  const items = steps.map((item) => ({
    key: item.title,
    title: item.title,
    icon: item.icon,
  }));
  const titles = [
    'Personal Information',
    'Registration Inforomation',
    'Additional Information',
  ];
  const [formData, setFormData] = useState({});
  const onFinish = (values) => {
    const finalFormData = { ...formData, ...values };
    console.log('Final Success:', finalFormData);

    
    mutate({
      first_name: finalFormData.first_name,
      last_name: finalFormData.last_name,
      email: finalFormData.email,
      country: finalFormData.country,
      role: finalFormData.role,
      language: finalFormData.language,
      first_conference: finalFormData.first_conference,
      expectations: finalFormData.expectations,
      goals: finalFormData.goals,
      t_shirt_size:finalFormData.t_shirt_size,
      socials:finalFormData.socials,
      can_stay_with_opposite_sex: finalFormData.can_stay_with_opposite_sex,
      food_request: finalFormData.food_request,
      have_allergies: finalFormData.have_allergies,
      emergencyContact: finalFormData.emergencyContact,
      availability: finalFormData.availability,
      
    });
  };

  const onFinishFailed = (errorInfo) => {
    // console.log('Failed:', errorInfo);
  };

  return (
    <div>
      <WACSNavbar />
      <RegisterHero />
      <div className="my-12 px-4 max-w-[1300px] mx-auto">
        <Steps
          responsive={true}
          current={current}
          onChange={onChange}
          items={items}
        />
        <div className="my-20">
          <div className="flex flex-col gap-1 my-6">
            <span>STEP {current + 1}</span>
            <span className="text-[#FF5500] font-bold text-[20px] md:text-[32px]">
              {titles[current]}
            </span>
          </div>
          <Form
            requiredMark={false}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            form={form}
            layout="vertical"
          >
            {steps[current].content}
            <div className="my-5 gap-5 flex">
              {current > 0 && (
                <Button className="rounded-none" onClick={() => prev()}>
                  Previous
                </Button>
              )}
              {current < steps.length - 1 && (
                <Button
                  type="primary"
                  className="hover:!bg-[#ff5500] bg-[#ff5500] rounded-none"
                  onClick={() => next()}
                >
                  Next
                </Button>
              )}

              {current === steps.length - 1 && (
                <Button
                  htmlType="submit"
                  className="bg-[#ff5500] rounded-none text-white "
                >
                  {isPending ?  <Loading3QuartersOutlined spin/> : <span>Done</span>}
                </Button>
              )}
            </div>
          </Form>
        </div>
      </div>
      <WACSFooter />
    </div>
  );
}

export default WACSRegister;
